<template>
  <div class="winner-page-main" id="winnerFormWrap">    
    <FormExpiredAndRegistered
        v-if="formExpired || alreadyRegistered || formDisabled || uploadedWinner === true"
        :formExpired="formExpired"
        :alreadyRegistered="alreadyRegistered"
        :formDisabled="formDisabled"
        :winnerType="winnerType"
    />

    <div v-else class="winner-page__main-content">
      <CodeFillingWinnerformHero :winnerType="winnerType" :needBtn="true" />
      
      <div
          class="winner-page__codefilling-form-background second-background-inner page-block"
          :class="{ 'congrat-background-space': uploadedWinner === true }"
      >
        <h2 class="text-center text-secondary" style="font-weight: 400;">{{tr('winner-title')}}</h2>
        <p class="codeFillingText__lead text-white">{{tr('winner-sub-title')}}</p>
        
          <div class="container-xl">
            <div class="row">
              <div class="col-md-12 col-lg-10 offset-lg-1">              
                <div class="codefilling-form">                
                    <validation-observer
                        v-if="formStatus === false"
                        v-slot="{ handleSubmit }"
                        tag="div"
                        ref="winnerFormObserver"
                    >
                      <form
                          class="form form--codefilling"
                          @submit.prevent="handleSubmit(formSubmit)"
                          id="winnerForm"
                      >
                        <div class="w-100">
                          <div class="col-12">
                            <div class="winner-codefilling__form">       
                              <h3 class="mt-0">{{tr('winning-block-title-1')}}</h3>                   
                              <div class="form-group form-row">
                                <div class="surname-row input--info">
                                  <div class="form-label">{{tr('winning-form-vezeteknev')}}</div>
                                  <input
                                      v-model="form.surname"
                                      type="text"
                                      class="form-control"
                                      name="surname"
                                      id="surname"
                                      disabled
                                  />
                                </div>
                                <div class="forname-row input--info">
                                  <div class="form-label">{{tr('winning-form-keresztnev')}}</div>
                                  <input
                                      v-model="form.forename"
                                      type="text"
                                      class="form-control"
                                      name="forname"
                                      id="forename"
                                      disabled
                                  />
                                </div>
                              </div>
                              <div class="form-group mb-0 form-row">
                                 
                                <div class="email-row input--info">
                                  <div class="form-label">{{tr('winning-form-email')}}</div>
                                  <input
                                      v-model="form.email"
                                      type="text"
                                      class="form-control"
                                      name="E-mail cím"
                                      id="email"
                                      placeholder="pl. tothlaszlo@mail.com"
                                      disabled
                                  />
                                </div>
                                <div class="form-group input--info phone-row">
                                  <ValidationProvider
                                      rules="required"
                                      v-slot="{ failedRules, errors }"
                                      vid="phone"
                                  >               
                                    <div class="d-flex align-itemx-center">
                                      <div class="form-label">{{tr('winning-form-phone')}}</div>
                                        <!-- <div
                                            class="icon-info"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            :title="tr('winning-form-phone-tooltip')"
                                        ></div>                                         -->
                                    </div>                        
                                    <input
                                        v-model="form.phone"
                                        type="text"
                                        class="form-control"
                                        name="phone"
                                        id="phone"
                                        :placeholder="tr('main-form-phone-placeholder')"
                                    />
                                    <span class="error hide" v-if="getErrorMessage('phone', Object.entries(failedRules)[0])">
                                      {{ getErrorMessage("phone", Object.entries(failedRules)[0]) }}
                                    </span>
                                    <div class="error" v-else>{{errors[0]}}</div>
                                  </ValidationProvider>
                                </div>                                                                                           
                              </div>
                                   <div class="form-group form-row">
                                    <div v-if="needTax"
                                        class=" form-group zip-row input--info">
                                        <ValidationProvider
                                                :rules="{required: true}"
                                                v-slot="{ failedRules, errors }"
                                                vid="tax"
                                            >
                                            <div class="d-flex align-itemx-center">
                                                <div class="form-label">{{tr('winning-form-tax')}}</div>
                                                <div
                                                    class="icon-info"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    :title="tr('winning-form-tax-tooltip')"
                                                ></div>
                                            </div>
                                            <input
                                                v-mask="'#############'"  
                                                v-model="form.tax_number"
                                                type="text"
                                                class="form-control"
                                                name="tax_number"
                                                id="tax_number"
                                                :placeholder="tr('winning-form-tax-placeholder')"
                                            />
                                            <span class="error" v-if="getErrorMessage('tax', Object.entries(failedRules)[0])">
                                                {{ getErrorMessage("tax", Object.entries(failedRules)[0]) }}
                                            </span>
                                            <div class="error" v-else >{{errors[0]}}</div>
                                            </ValidationProvider>
                                    </div>
                                    <div v-if="needBirth"
                                        class=" form-group zip-row input--info">
                                        <ValidationProvider
                                                :rules="{required: true}"
                                                v-slot="{ failedRules, errors }"
                                                vid="tax"
                                            >
                                            <div class="d-flex align-itemx-center">
                                                <div class="form-label">{{tr('winning-form-birth')}}</div>
                                            </div>
                                            <input 
                                                v-model="form.birth_number"
                                                type="text"
                                                class="form-control"
                                                name="birth_number"
                                                id="birth_number"
                                            />
                                            <span class="error" v-if="getErrorMessage('birth_number', Object.entries(failedRules)[0])">
                                                {{ getErrorMessage("birth_number", Object.entries(failedRules)[0]) }}
                                            </span>
                                            <div class="error" v-else >{{errors[0]}}</div>
                                            </ValidationProvider>
                                    </div>
                                    <div v-if="needBirthDate"
                                        class=" form-group zip-row input--info">
                                        <ValidationProvider
                                                :rules="{required: true}"
                                                v-slot="{ failedRules, errors }"
                                                vid="tax"
                                            >
                                            <div class="d-flex align-itemx-center">
                                                <div class="form-label">{{tr('winning-form-birth-date')}}</div>
                                            </div>
                                            <input 
                                                v-model="form.birth_date "
                                                type="text"
                                                class="form-control"
                                                name="birth_date"
                                                id="birth_date"
                                            />
                                            <span class="error" v-if="getErrorMessage('birth_date', Object.entries(failedRules)[0])">
                                                {{ getErrorMessage("birth_date", Object.entries(failedRules)[0]) }}
                                            </span>
                                            <div class="error" v-else >{{errors[0]}}</div>
                                            </ValidationProvider>
                                    </div>
                                </div> 
                              <!-- cím adatok - utólag kérés elrejteni -->
                              <div>
                                <h3>{{tr('winning-block-title-2')}}</h3>
                                <div class="form-group mb-0 form-row">
                                  <div
                                      class=" form-group zip-row input--info">
                                      <!-- zip - magyar oldalnál -->
                                    <ValidationProvider     
                                        v-if="$store.state.currentLang == 'hu'"                             
                                        :rules="{required: true,  min: { length: 4 }  }"
                                        v-slot="{ failedRules, errors }"
                                        vid="zip"
                                    >
                                      <div class="d-flex align-itemx-center">
                                        <div class="form-label">{{tr('winning-form-zip')}}</div>
                                          <!-- <div
                                              class="icon-info"
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              :title="tr('winning-form-zip-tooltip')"
                                          ></div>                                       -->
                                      </div>
                                      <input
                                          v-mask="'####'"
                                          v-model="form.zip"
                                          type="text"
                                          class="form-control"
                                          name="zip"
                                          id="zip"
                                          :placeholder="tr('winning-form-zip-placeholder')"
                                      />
                                      <span class="error hide" v-if="getErrorMessage('zip', Object.entries(failedRules)[0])">
                                        {{ getErrorMessage("zip", Object.entries(failedRules)[0]) }}
                                      </span>
                                      <div class="error" v-else>{{errors[0]}}</div>
                                    </ValidationProvider>
                                    <!-- zip - egyéb nyelven -->
                                    <ValidationProvider     
                                        v-else
                                        :rules="{required: true }"
                                        v-slot="{ failedRules, errors }"
                                        vid="zip"
                                    >
                                      <div class="d-flex align-itemx-center">
                                        <div class="form-label">{{tr('winning-form-zip')}}</div>
                                          <!-- <div
                                              class="icon-info"
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              :title="tr('winning-form-zip-tooltip')"
                                          ></div>                                       -->
                                      </div>
                                      <input                                        
                                          v-model="form.zip"
                                          type="text"
                                          class="form-control"
                                          name="zip"
                                          id="zip"
                                          :placeholder="tr('winning-form-zip-placeholder')"
                                      />
                                      <span class="error hide" v-if="getErrorMessage('zip', Object.entries(failedRules)[0])">
                                        {{ getErrorMessage("zip", Object.entries(failedRules)[0]) }}
                                      </span>
                                      <div class="error" v-else>{{errors[0]}}</div>
                                    </ValidationProvider>
                                  </div>
                                  <div class="form-group input--info city-row">
                                    <ValidationProvider
                                        :rules="{required: true }"
                                        v-slot="{ failedRules, errors }"
                                        vid="city"
                                    >
                                        <div class="d-flex align-itemx-center">
                                          <div class="form-label">{{tr('winning-form-city')}}</div>
                                          <!-- <div
                                              class="icon-info"
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              :title="tr('winning-form-city-tooltip')"
                                          ></div>                                         -->
                                        </div>
                                      <input
                                          v-model="form.city"
                                          type="text"
                                          class="form-control"
                                          name="Város"
                                          id="city"
                                          :placeholder="tr('winning-form-city-placeholder')"
                                      />
                                      <span class="error" v-if="getErrorMessage('city', Object.entries(failedRules)[0])">
                                        {{ getErrorMessage("city", Object.entries(failedRules)[0]) }}
                                      </span>
                                      <div class="error" v-else>{{errors[0]}}</div>
                                    </ValidationProvider>
                                  </div>
                                </div>
                                <div class="form-group input--info form-row">
                                  <div class="input--info street-row">
                                    <ValidationProvider
                                        :rules="{required:true }"
                                        v-slot="{ failedRules, errors }"
                                        vid="street"
                                    >
                                      <div class="d-flex align-itemx-center">
                                        <div class="form-label">{{tr('winning-form-street')}}</div>
                                          <!-- <div
                                              class="icon-info"
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              :title="tr('winning-form-street-tooltip')"
                                          ></div>                                       -->
                                      </div>
                                      <input
                                          v-model="form.street"
                                          type="text"
                                          class="form-control"
                                          name="Utca"
                                          id="street"
                                          :placeholder="tr('winning-form-street-placeholder')"
                                      />
                                      <span class="error" v-if="getErrorMessage('street', Object.entries(failedRules)[0])">
                                        {{ getErrorMessage("street", Object.entries(failedRules)[0]) }}
                                      </span>
                                      <div class="error" v-else>{{errors[0]}}</div>
                                    </ValidationProvider>
                                  </div>
                                  <div class="input--info house-row">
                                    <ValidationProvider
                                        :rules="{required: true }"
                                        v-slot="{ failedRules, errors }"
                                        vid="house"
                                    >
                                      <div class="d-flex align-itemx-center">
                                        <div class="form-label">{{tr('winning-form-house')}}</div>
                                          <!-- <div
                                              class="icon-info"
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              :title="tr('winning-form-house-tooltip')"
                                          ></div>                                       -->
                                      </div>
                                      <input
                                          v-model="form.house"
                                          type="text"
                                          class="form-control"
                                          name="house"
                                          id="house"
                                          placeholder="21"
                                      />
                                      <span class="error" v-if="getErrorMessage('house', Object.entries(failedRules)[0])">
                                        {{ getErrorMessage("house", Object.entries(failedRules)[0]) }}
                                      </span>
                                      <div class="error" v-else >{{errors[0]}}</div>
                                    </ValidationProvider>
                                  </div>
                                </div>
                                    <!-- megjegyzés -->
                                  <div class="form-group input--info form-row">                                  
                                    
                                    <div class="input--info house-row">
                                      <div class="d-flex align-itemx-center">
                                        <div class="form-label">{{tr('winning-form-comment')}}</div>
                                          <!-- <div
                                              class="icon-info"
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              :title="tr('winning-form-comment-tooltip')"
                                          ></div>                                       -->
                                        </div>
                                        <textarea
                                            v-model="form.comment"
                                            type="text"
                                            class="form-control"
                                            name="comment"
                                            id="comment"
                                            :placeholder="tr('winning-form-comment-placeholder')"
                                        />   
                                  </div>
                            
                             
                                </div>                                                   
                                <!-- info text -->
                                <div class="info-block mb-4">                      
                                    <img src="@/assets/img/icons/ic-info.svg" class="mr-2" />
                                    {{tr('winning-form-info-text')}}                                
                                </div>
                              </div>
                              <!-- button                               -->
                              <div class="d-flex justify-content-center mt-4">
                                <button type="submit" class="btn btn-blue">
                                  {{tr('winning-form-submit-text')}}               
                                </button>
                              </div>
                            </div>
                            <div>

                            </div>
                          </div>
                        </div>
                      </form>
                    </validation-observer>                
                </div>
              </div>
            </div>
          </div>        
      </div>      
    </div>    
  </div>
</template>

<script>
import {mask} from "vue-the-mask";
import $ from "jquery";
/* eslint-disable */
import CodeFillingWinnerformHero from '@/components/CodeFillingWinnerFormHero.vue'
import {ValidationProvider, ValidationObserver, extend} from "vee-validate";
import {required, email} from "vee-validate/dist/rules";
import {setInteractionMode} from "vee-validate";
import FormExpiredAndRegistered from "@/components/FormExpiredAndRegistered";

setInteractionMode("eager");
// Add a validation rules

extend("email", {
  ...email  
});
extend("required", {
  ...required  
});
extend("min", {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ["length"]  
});


export default {
  data() {
    return {
      form: {
        surname: "",
        forename: "",
        email: "",
        zip: "",
        city: "",
        phone: "",
        street: "",
        house: "",
        comment:"",
        tax_number: "",
        birth_number: "",
        hash: this.$route.query.hash,
        hash2: this.$route.query.hash2,
      },
      needBirth: false,
      needBirthDate:false,
      needTax: false,
      needAddressData:false,
      formStatus: false,
      alreadyRegistered: false,
      responseErrors: null,
      triedFillForm: false,
      uploadedWinner: false,
      winnerType: null,
      uploadedError: false,
      imageError: false,
      uploadedFiles: [],
      formExpired: false,
      formDisabled: false,
      formIsSubmitting: false
    };
  },
  components: {
    FormExpiredAndRegistered,
    ValidationProvider,
    ValidationObserver,
    CodeFillingWinnerformHero 
  },
  directives: {
    mask,
  },

  methods: {
    backToForm() {
      this.$router.push("/");
      window.scrollTo(0, 0);
    },   
    formSubmit() {
      var _this = this;      

      if (!_this.formIsSubmitting) {
        _this.formIsSubmitting = true

        _this.$axios
            .post(process.env.VUE_APP_API_URL + "index.php/step2/upload", _this.form, {
              
            })
            .then((response) => {
              var _this = this;              

              if (!response.data.status) {                                
                _this.goToError(error) 
              } else {
                //success
                _this.formStatus = true;
                _this.uploadedWinner = true;
                // scroll to success screen's top
              
                  let element = document.querySelector('#success-data-upload')
                  if (element){
                      _this.scrollToElement(element, 20)       
                  }
              }

              _this.formIsSubmitting = false
              
            }).catch(function (error) {
              console.log(error);
              _this.formIsSubmitting = false
              _this.goToError(error)                        

            });
      } else {
        console.log('double form submit')
      }


    }, 
    goToError(err){            
        let _this = this
        //scroll to the first error
        this.setErrorMsgs(err, 'winnerFormObserver').then(()=>{                               
          let firstError = document.querySelector('#winnerForm .error')
          if (firstError){
              _this.scrollToElement(firstError, 20)       
          }          
        })                                       
    },
  },
  mounted() {
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();  
    }, 1000);
    
  },
  updated() {
    $('[data-toggle="tooltip"]').tooltip();
  },
  created() {
    let _this = this

//    $('[data-toggle="tooltip"]').tooltip();

    _this.$axios.get(
            process.env.VUE_APP_API_URL +
            "index.php/step2/hash?hash=" +
            this.$route.query.hash +
            "&hash2=" +
            this.$route.query.hash2
        )
        .then((response) => {
          console.log(response.data)

          if (response.data.status === false) {
            //not winner or not good hash
            console.log('error')
            this.$router.push({name: "APCodeUpload"});
          } else if (response.data.status.toLowerCase() == "ok") {
            //a winner opened the page
            if (!response.data.winner) {
              // console.log("nem nyertes")
              this.$router.push({name: "APCodeUpload"});
            } else {
              
              if (response.data.winner.zip != null) {
            
                _this.formStatus = true;
                _this.alreadyRegistered = true;
              }
              
              _this.winnerType = response.data.winner.type;
              _this.form.email = response.data.winner.email;
              _this.form.surname = response.data.winner.surname;
              _this.form.forename = response.data.winner.forename;
              //user data
              _this.form.street = response.data.winner.street;
              _this.form.house = response.data.winner.house;
              _this.form.phone = response.data.winner.phone;
              _this.form.city = response.data.winner.city;
              _this.form.zip = response.data.winner.zip;
               
              if(response.data.tax_number_required){
                _this.needTax = true;
              }

              if(response.data.birth_number_required ){
                _this.needBirth = true;
              }

              if(response.data.birth_date_required ){
                _this.needBirthDate = true;
              }
            }
          } else if (response.data.status.toLowerCase() == "expired" && response.data.winner.zip == null) {
            //the time is over than 5 days
            this.formExpired = true;
            _this.winnerType = response.data.winner.type;
          } else if (response.data.status.toLowerCase() == "expired" && response.data.winner.zip != null) {
            //the time is over than 5 days, but sent data
            this.alreadyRegistered = true;
            _this.winnerType = response.data.winner.type;
          } else if (response.data.status.toLowerCase() == "disabled") {
            // console.log("status disabled");
            this.formDisabled = true;
            _this.winnerType = response.data.winner.type;
          }
        }).catch(function(){       
          //the url isn't valid  
          _this.$router.push({name: "APCodeUpload"});
        })
  } 
};
</script>
